// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  min-height: 100%;
  background-color: #ecedee;
  background-size: cover;
}

#appWrapper,
#mainApp {
  height: 100%;
  width: 100%;
  margin: 0;
}

#appWrapper {
  display: flex;
}

#mainApp > div:not(:empty) {
  margin-top: 68px;
  height: calc(100% - 72px);
  width: 100%;
}

@media screen and (min-width: 576px) {
  #mainApp > div:not(:empty) {
    padding-left: 64px;
    height: calc(100% - 76px);
  }
}

@media screen and (min-width: 992px) {
  #mainApp > div:not(:empty) {
    padding-left: 156px;
    margin-top: 72px;
    height: calc(100% - 76px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,YAAY;EACZ,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;IAClB,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;EAC3B;AACF","sourcesContent":["html,\nbody {\n  min-height: 100%;\n  background-color: #ecedee;\n  background-size: cover;\n}\n\n#appWrapper,\n#mainApp {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n}\n\n#appWrapper {\n  display: flex;\n}\n\n#mainApp > div:not(:empty) {\n  margin-top: 68px;\n  height: calc(100% - 72px);\n  width: 100%;\n}\n\n@media screen and (min-width: 576px) {\n  #mainApp > div:not(:empty) {\n    padding-left: 64px;\n    height: calc(100% - 76px);\n  }\n}\n\n@media screen and (min-width: 992px) {\n  #mainApp > div:not(:empty) {\n    padding-left: 156px;\n    margin-top: 72px;\n    height: calc(100% - 76px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
