/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  CartModal: {
    backupSnapshot: 'A backup snapshot will be created for you.',
    cart: 'Cart',
    confirmAndCreateBackup: 'Publish and create a backup',
    emptyState: 'No pending changes',
    goBack: 'Go back',
    nameOfBackup: 'Name of backup',
    noGoBack: 'No, go back',
    over249Warning:
      'You can add one more change to cart before you reach the maximum number of changes in KPS.',
    over250Warning:
      'You have reached the maximum number of changes in KPS. Please publish your cart before adding more changes.',
    posRebootRequired: 'KPS and POS reboots are required',
    publishAllCartChangesForStore:
      'Publish All {{cartChangesName}} Changes for {{storeName}}?',
    publishCartChangesNow: 'Publish {{cartChangesName}} changes',
    publishChanges: 'Yes, publish changes',
    rebootNotRequired: 'No reboot is required',
    rebootRequired: 'A KPS reboot is required',
    remove: 'Remove',
    removeAllConfirmation: 'Yes, remove all {{cartChangesName}} changes',
    removeAllUpdates: 'Remove all {{cartChangesName}} changes',
    removeConfirmation:
      'Are you sure you want to remove all {{cartChangesName}} updates?',
    scheduleKpsChanges: 'Schedule KPS changes',
    scheduleKpsChangesHelpText:
      'Select a date and time to publish these changes to the store.',
    scheduleNow: 'Schedule now',
    sendKpsCartChangesNow: 'Publish KPS changes now',
  },
  SelectStore: {
    header: 'Find a store',
    next: 'Next',
    prev: 'Prev',
    selectStore: 'Select Store',
    selectStoreSearchInputPlaceholder: 'Search store name or number',
    storeRange:
      '{{startingStoreNumber}} - {{endingStoreNumber}} of {{totalStores}}',
  },
  SideNav: {
    control: 'CONTROL',
    point: 'POINT',
  },
  TopNav: {
    cancel: 'Cancel',
    changeStore: 'Change',
    control: 'CONTROL',
    point: 'POINT',
    searchInputPlaceholder: 'Search ControlPoint',
  },
};
